@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Serif:ital,wght@0,500;0,700;1,500;1,700&display=swap");

@import url('https://fonts.cdnfonts.com/css/heavitas'); //font-family: 'Heavitas', sans-serif;
@import url('https://fonts.cdnfonts.com/css/avenir'); //font-family: 'Avenir', sans-serif;

body {
  // background: #f3f3f3 !important;  // Brown(Joel)
  background: #ebebeb !important;  // .co.za
}

p, span {
  margin: 0 !important;
  padding: 0 !important;
  color: #4D1800;

}

.header-mobile Link, a {
  color: rgba(97, 81, 78, 1) !important;
}

.header-default Link, a {
  color: rgba(97, 81, 78, 1);
}

// b, i, .fw-bold {
//   color: #212529 !important;
// }

@media screen and (max-width: 480px) {
  .card-welcome .card-body p,
  .card-welcome .card-body li {
    font-size: 13px;
    line-height: 14.3px;
  }
}

@media screen and (max-width: 480px) {
  .card-contributions .card-body {
    padding-bottom: 8px !important;
  }
  .card-contributions .card-body .card-item {
    padding: 8px 0 !important;
  }
  .card-contributions .card-body .card-item-last {
    padding-top: 8px !important;
  }
}

table th {
  padding: 16px !important;
  color: #4D1800 !important;
  background: #F2FBFA !important;
  // border-top: 1px solid #DEE2E6 !important;
}

table td {
  padding: 16px !important;
  color: #4D1800 !important;
  vertical-align: middle;
}

.table-donors table td {
  padding: 11px 16px !important;
}


.header-default {
  display: flex;
  flex-direction: row;
  position: sticky;;
  left: 0px;
  top: 0px;
  margin-bottom: 32px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .logo-wrapper {
    margin-left: 24px;
  }

  @media screen and (min-width: 992px) {
    .logo-wrapper {
      margin-left: 80px;
    }
  }

  .nav-menu {
    justify-content: space-evenly;
    background-color: inherit;
    margin-right: 24px;
    width: auto;
  }
  
  @media screen and (min-width: 992px) {
    .nav-menu {
      margin-right: 80px;
    }
  }

  .nav-link:hover,
  .nav-link:focus,
  .nav-link:active,
  .nav-link-active {
    text-shadow: -.25px -.25px 0 rgba(97, 81, 78, 1), .25px .25px rgba(97, 81, 78, 1) !important;
    border-bottom: 2px solid rgba(97, 81, 78, 1)!important;

    &.nav-link-mobile {
      color: rgba(97, 81, 78, 1);
      text-shadow: -.25px -.25px 0 rgba(97, 81, 78, 1), .25px .25px rgba(97, 81, 78, 1) !important;
      border-bottom: 2px solid rgba(97, 81, 78, 1)!important;
    }
  }

}

.header-bg {
  // background: #332516;  // Brown(Joel)
  // background: #7e6d65;  // .co.za
  background: #ebebeb;  // .co.za
  //p, a {
    // color: #FFFFFF !important;
    // color: #5b4a38 !important;
  //}
}

.header-mobile .nav-link,
.header-default .nav-link {
  border-bottom: 2px solid transparent;
  margin: 12px 16px !important;
  padding: 4px 0 2px !important;
}

.nav-link-mobile {
  color: rgba(97, 81, 78, 1) !important;
  padding: 8px 6px !important;
}

.header-mobile {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .logo-wrapper {
    margin-left: 24px;
  }

  &.nav-link-mobile {
    color: rgba(97, 81, 78, 1);
  }
}

.nav-mobile {
  background: #b2b2b2;
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  margin-bottom: 24px;
}

.jc-header-logo {
  margin-right: 12px;
}

.nav-separator {
  display: block;
  background-color: #212529;
  opacity: .5;
  margin: 22px 0;
  width: 1px;
}

.nav-mobile .nav-separator {
  margin: 8px 0 !important;
}

.table-upcoming-consecrations tr:last-child td:first-child,
.table-recent-funerals tr:last-child td:first-child,
.table-profile-pics tr:last-child td:first-child,
.table-consecration tr:last-child td:first-child,
.table-upcoming-funerals tr:last-child td:first-child,
.table-weekly-yahrzeit tr:last-child td:first-child,
.donate-recent-donors tr:last-child td:first-child {
  border-bottom-left-radius: 12px !important;
}

.table-upcoming-consecrations tr:last-child td:last-child,
.table-profile-pics tr:last-child td:last-child,
.table-consecration tr:last-child td:last-child,
.table-upcoming-funerals tr:last-child td:last-child,
.table-weekly-yahrzeit tr:last-child td:last-child,
.donate-recent-donors tr:last-child td:last-child {
  border-bottom-right-radius: 12px !important;
}

.table-upcoming-funerals {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow-y: auto;
}

.donate-recent-donors td {
  padding: 8px 16px !important;
}

.table-information td {
  padding: 4px 16px !important;
}

.table-information-mobile td {
  padding: 4px 8px !important;
}

.donate-recent-donors-wrapper {
  height: 210px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

@media screen and (max-width: 480px) {
  .donate-recent-donors-wrapper {
    height: 352px !important;
  }
}

.table-donors {
  height: 352px;
  overflow: auto;
}

.table-donors th {
  position: sticky;
  top: 0;
  // border-top: 1px solid #DEE2E6 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.deceased-links::-webkit-scrollbar,
.table-donors::-webkit-scrollbar,
.table-consecration::-webkit-scrollbar,
.table-weekly-yahrzeit::-webkit-scrollbar,
.table-upcoming-consecrations::-webkit-scrollbar,
.table-recent-funerals::-webkit-scrollbar,
.table-upcoming-funerals::-webkit-scrollbar,
.table-profile-pics::-webkit-scrollbar,
.card-wall-posts .card-body::-webkit-scrollbar {
  display: none !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.deceased-links,
.table-donors,
.card-posts,
.table-weekly-yahrzeit,
.table-consecration,
.table-upcoming-consecrations,
.table-recent-funerals,
.table-upcoming-funerals,
.table-profile-pics,
.card-wall-posts .card-body {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.card-posts {
  height: 650px !important;
}

.card-posts .card-body {
  overflow: auto !important;
}

.table-weekly-yahrzeit {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 292px !important;
  overflow-y: auto;
}

@media screen and (max-width: 480px) {
  .table-weekly-yahrzeit {
    height: 333px !important;
  }
}

@media screen and (max-width: 480px) {
  .table-upcoming-consecrations {
    height: 100% !important;
  }
}

.table-upcoming-consecrations {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 292px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-recent-funerals {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 292px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-consecration {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  // height: 284px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-profile-pics {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 407px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.card {
  margin-bottom: 24px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
}

.card-body {
  min-height: 57px;
}

.card-header {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom: 1px solid #DEE2E6 !important;
  padding: 16px !important;
}

.card-header-bg {
  // background: #93521d;  //Brown(Joel) 
  // background: #5b4a38;  // .co.za 
  background: #983A08;  // orange 
  p {
    color: #FFFFFF !important;
  }
}

.card-footer {
  padding: 16px !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-top: 1px solid #DEE2E6 !important;
}

.card-footer-signature {
  padding: 12px 16px !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-top: 1px solid #DEE2E6 !important;
}

.card-item {
  padding: 16px 0;
}

.card-item-first {
  padding-bottom: 16px;
}

.card-item-last {
  padding-top: 16px;
}

.card-hr {
  margin: 0;
  margin-left: -16px;
  width: calc(100% + 32px);
  opacity: 1;
  border-top: 1px solid #DEE2E6 !important;
}

.card-welcome {
  height: 765px !important;
}

.card-wall-posts .card-body {
  overflow-y: auto;
}

.deceased-wall-posts {
  height: 388px;
}

@media screen and (min-width: 992px) {
  .deceased-wall-posts {
    height: 692px;
  }
  
}

.home-wall-posts .card-body {
    height: 408px !important;
}

// .card-upcoming-funerals {
//   height: 182px !important;
// }

// #61514e  --  Brown(Joel)
// #ad5229  --  .co.za

.card .btn {
  background: #4D1800 !important;
}

.btn-add-picture {
  background: #4D1800 !important;
  color: #FFFFFF !important;
  padding: 4.2px, 7.8px, 4.2px, 7.8px;
  border-radius: 100px;
  font-size: 12px;
  border: 1px solid #4D1800 !important;
}

.btn-image-scale-plus {
  background:  url("components/images/scale-plus.svg") no-repeat;
  width: 36px;
  height: 36px;
  background-size: contain;
  border: none;
  filter: invert(12%) sepia(24%) saturate(5226%) hue-rotate(4deg) brightness(96%) contrast(104%);
}

.btn-image-scale-minus {
  background:  url("components/images/scale-minus.svg") no-repeat;
  width: 36px;
  height: 36px;
  background-size: contain;
  border: none;
  filter: invert(12%) sepia(24%) saturate(5226%) hue-rotate(4deg) brightness(96%) contrast(104%);
}

.jc-notification-wrapper {
  border-bottom: 1px solid #DEE2E6 !important;
}

.location-1 {
  border-right: 1px solid #DEE2E6 !important;
}

.location-2 {
  background: rgba(0, 0, 0, 0.03) !important;
  border-bottom-right-radius: 12px !important;
}

#contributions_bar_wrapper {
  background: #E3E1DE;
  height: 16px;
  border-radius: 20px;
}

.btn {
  border-radius: 20px !important;
}

.btn-dark {
  background: #4D1800 !important;
  border: 1px solid #4D1800 !important;
}

.btn-light {
  background: transparent !important;
}

.btn-donate {
  border-radius: 8px !important;
  width: 100%;
  background-color: transparent !important;
  border-color: #4D1800 !important;
  color: #4D1800 !important;
}

.btn-donate:hover {
  background-color: #4D1800 !important;
  border-color: #4D1800 !important;
  color: #ffffff !important;
}

.btn-donate-active {
  background-color: #4D1800 !important;
  border-color: #4D1800 !important;
  color: #ffffff !important;
}

.btn-modal {
  background-color: #4D1800 !important;
}

.btn-submit {
  background-color: #4D1800 !important;
}

.deceased-person-button:hover {
  background: #4D1800 !important;
  color: #ffffff !important;
}

.deceased-person-button {
  color: #212529 !important;
}

.wall-post-block {
  border-radius: 0px 8px 8px 8px;
}

.page-title {
  margin-bottom: 32px;
}

.auto-height {
  height: calc(100% - 24px) !important;
}

.form-input {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

.modal {
  overflow-y: auto !important;
}

.modal .modal-dialog {
  width: calc(100% - 48px)!important;
  margin: auto!important;
}

.modal * .form-control {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

.modal * .form-select {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

#subscriptionsForm .form-control {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

#leafForm .form-control {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

#leafForm .form-select {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

.sort-by .form-control {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #F8F9FA !important;
  height: 38px !important;
  max-width: 200px !important;
}

.contact-col-border .form-control {
  border-radius: 20px !important;
  background-color: #F8F9FA !important;
}

.modal-dialog {
  max-width: 792px !important;
}

.form-check-input:checked {
  background-color: #212529 !important;
  border-color: #212529 !important;
}

.search-wrapper * .search-first {
  height: 48px;
  border: 1px solid #CED4DA !important;
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  background-color: #F2FBFA !important;
}

.search-wrapper * .search-last {
  height: 48px;
  border: 1px solid #CED4DA !important;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  background-color: #F2FBFA !important;
}

.search-button {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  gap: 8px;
  padding: 8px 16px;
  color: #FFFFFF;
  border: 1px solid #4D1800 !important;
  border-left: none !important;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  background-color: #4D1800 !important;
}

.search-results-container ul {
  background-color: transparent !important;
}

.search-results-container li {
  background-color: transparent !important;
  border-bottom: 1px solid #CED4DA!important;
  align-items: center;
}

.search-results-container li img {
  width: 64px;
  height: 64px;
}

.image-profile {
  border-radius: 50%;
  width: 136px;
  height: 100%;
  object-fit: cover;
}

.image-profile-wrapper {
  width: auto;
  height: 136px;
}

.recent-image {
  border-radius: 50%;
  width: 48px;
  height: 100%;
  object-fit: cover;
}

.recent-image-wrapper {
  width: auto;
  height: 48px;
  overflow: initial;
}

.recent-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.tree-wrapper::before {
  content: "";
  position: absolute;
  top: 63px;
  right: 0px;
  bottom: 71px;
  left: 0px;
  background-image: url("components/images/sky.gif");
  opacity: 10%;
  background-size: cover;
  filter: blur(20px);
}

.leaf-tree-wrapper::before {
  content: "";
  position: absolute;
  top: 63px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url("components/images/sky.gif");
  opacity: 10%;
  background-size: cover;
  filter: blur(20px);
}

.tree-wrapper div {
  height: auto;
}
@media screen and (min-width: 768px) {
  .tree-wrapper div {
    height: 252px;
  }
}
@media screen and (min-width: 992px) {
  .tree-wrapper div {
    height: 426px;
  }
}

.leaf-tree-wrapper div {
  height: auto;
}
@media screen and (min-width: 768px) {
  .leaf-tree-wrapper div {
    height: 257px;
  }
}

.img-tree {
  height: auto !important;
}
@media screen and (min-width: 992px) {
  .img-tree {
    height: 255px !important;
  }
}

.menu-active {
  opacity: 1;
}

/* ------------------------------------------------- */
.align-end {
  text-align: right;
}

.jc-border {
  border: 1px solid #DEE2E6 !important;
}

.jc-border-12 {
  border: 1px solid #DEE2E6 !important;
  border-radius: 12px !important;
}

.jc-bg-light {
  background-color: #DEE2E6 !important;
}

.jc-bg-med {
  background-color: #E9ECEF !important;
}

.jc-bg-dark {
  background-color: #ABA099 !important;
}

.jc-notification {
  background-color: #F1FBFF !important;
  border: 1px solid #D2E0E5;
}

.jc-txt-heading {
  // font-family: "Heavitas", sans-serif !important;
  font-family: "Avenir", sans-serif !important;
  font-weight: bold !important;
}

.jc-txt-body {
  // font-family: "Heavitas", sans-serif !important;
  font-family: "Avenir", sans-serif !important;
  color: #4D1800;
}

.jc-txt-logo {
  font-family: "Heavitas", sans-serif !important;
  color: #4D1800 !important;
}

.jc-txt-light {
  color: #F1FBFF !important;
}

.jc-txt-dark {
  color: #212529 !important;
}

.jc-txt-blue {
  // color: #00729F !important;
  color: #198754 !important;
}

.jc-txt-black {
  color: #000000 !important;
}

.fs-1 {
  margin-bottom: 32px !important;
}

.fs-7 {
  font-size: 14px;
}

.fs-8 {
  font-size: 12px;
}

.donation-color {
  color: #212529!important;
}

.jc-grid {
  padding: 0px 16px;
}
@media screen and (min-width: 992px) {
  .jc-grid {
    padding: 0px 80px;
  }
}

.jc-footer {
  padding: 0px 24px;
}
@media screen and (min-width: 992px) {
  .jc-footer {
    padding: 0px 80px;
  }
}

.page-wrapper {
  width: 866px;
  margin: 0 16px;
}

.contact-col-border {
  border: none;
}
@media screen and (min-width: 768px) {
  .contact-col-border {
    border-right: 1px solid #AAE5E0;
  }
}

.contact-break {
  color: #212529;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .contact-break {
    display: none;
  }
}
.list-group-flush {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.progress {
  background: #f3f3f3 !important;
}

.progress-bar {
  background-color: #198754 !important;
}

.tombstone-placeholder {
  margin: 32px 32px 0;
  height: calc(100% - 32px);
  
  border-radius: 42px 42px 0px 0px;
  background-size: cover;

  border: 2px solid #212529;
  border-bottom: none;
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, .9);

  // border: 2px solid #6C757D;
  // border-bottom: none;
  // box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, .5);
}

.tombstone-content {
  padding: 16px 16px 0;
  p {
    font-size: 32px!important;
    line-height: 120%;
    mix-blend-mode: multiply;
    opacity: 0.6;
  }
}

.table-mobile-left {
  float: left;
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .table-mobile-left {
    border-bottom: none;
    padding-top: 8px !important;
    padding-bottom: 0px !important;
  }
  .table-mobile-right {
    float: left;
    width: 100%;
    text-align: left;
    border-top: none;
    padding-top: 0px !important;
    padding-bottom: 8px !important;
  }
}

@media screen and (max-width: 480px) {
  .table-profile-pics td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

@media screen and (max-width: 480px) {
  .deceased-person-wrapper {
    flex-direction: column;
  }
}

.deceased-links {
  overflow-x: scroll;
}

.jc-tooltip {
  position: relative;
  display: inline-block;
}

.jc-tooltip .jc-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000;
  color: #ffffff !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0 !important;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px !important;
}

.jc-tooltip .jc-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.jc-tooltip:hover .jc-tooltiptext {
  visibility: visible;
}

.leaf-test:hover path {
  fill: red!important;
}

.search-drop-svg {
  background-color: transparent;
  border: none;
}

.toggle-hide {
  display: none;
}

.leaf-form-message {
  align-Items: center;
  height: 448px;
}

.donate-form-message {
  align-Items: center;
  height: 560px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.card-bank-details .list-group-item:last-child {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

// .leaf-svg-path:hover {
//   fill: blue;
// }

// .tooltip {
//   background-color: indianred;
// }

// .tooltip-hidden {
//   visibility: hidden;
//   pointer-events: none;
// }








// EDIT PAGES
.table-edit-wrapper {
  border: 1px solid #dee2e6;
  border-radius: 12px !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
}

.table-edit-list th:first-child {
  border-top-left-radius: 12px !important;
}

.table-edit-list th:last-child {
  border-top-right-radius: 12px !important;
}

// .table-edit-list tr:first-child {
//   background: #5b4a38 !important;
//   border-top-left-radius: 12px !important;
//   border-top-right-radius: 12px !important;
// }

.table-edit-list th {
  position: sticky;
  top: 0;
  font-size: 20px;
  color: #ffffff !important;
  white-space: nowrap;
  background: #983A08 !important;
  text-shadow: 1px 1px #000000;
}


.table-edit-record th {
  position: sticky;
  top: 0;
  font-size: 20px;
  color: #000000 !important;
  white-space: nowrap;
  background: transparent !important;
  border-bottom: 1px solid #000000;
}

.table-edit-record td {
  color: #000000 !important;
  white-space: nowrap;
  background: transparent !important;;
}

.edit-tabs {
  .nav-link {
    color: #000000 !important;
    border-radius: 12px !important;
}

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #ffffff !important;
    background-color: #983A08 !important;
  }
}




#hide {
  display: inline-block;
  max-width: 0%;
  vertical-align: bottom;
  overflow: hidden;
  white-space: nowrap;
  transition: max-width .5s ease-in-out;
}

#deceasedId:hover #hide {
  max-width: 100%;
}

.edit-tooltip {
  position: relative;
  display: inline-block;
  cursor: copy;
}

.edit-tooltip .edit-tooltiptext {
  visibility: hidden;
  background-color: #000000;
  color: #ffffff !important;
  text-align: center;
  border-radius: 6px;
  padding: 4px 8px !important;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50% !important;
  margin-left: -60px !important;
  white-space: nowrap;
}

.edit-tooltip .edit-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -110px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.edit-tooltip:hover .edit-tooltiptext {
  visibility: visible;
}

.edit-copied {
  display: none;
}

.nowrap {
  white-space: nowrap;
}


#tooltipSpan {
  display: none;
  z-index: 999;

  background-color: #000000;
  color: #ffffff !important;
  text-align: center;
  border-radius: 6px;
  padding: 4px 8px !important;
  white-space: nowrap;
}

.tooltipShow {
  display: block !important;
  position:fixed;
  overflow:hidden;
}

.tooltip span {
  display:none;
}
.tooltip:hover span {
  display:block;
  position:fixed;
  overflow:hidden;
}



.link-grief-support {
  position: relative;
  // width: fit-content;
}

.link-grief-support:hover {
  .img-grief-support-video {
    visibility: visible !important;
  }
}

.img-grief-support {
  height: 100px;
  border-radius: 12px;
}

.img-grief-support-video {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-responsive iframe {
  border-radius: 20px;
  height: 488px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .video-responsive iframe {
    height: 300px !important;
  }
}

.float-left {
  float: left !important;
}


// #tooltip:hover {
//   display: none !important;
// }

// #tooltip:hover * #tooltipSpan {
//   display: none !important;
// }

/*# sourceMappingURL=style.css.map */